<template>	
    <div>
        <v-container
        class="bg-surface-variant mb-6" >
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="true"
                :filename="'Report' + this.FltID"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">	
                <section slot="pdf-content"> 
				<v-card-text>
			<!--
				1100
				800-->
					<v-row>
						<v-col cols="12">
							<table  width="100%" style="border: 0 !important;line-height: 0.1em;font-size: 8px;" >
								<tr>
									<td rowspan="12"><img width="150px" src="../../../../public/static/logo_sp.png" /></td>
									<td rowspan="12" style="font-size: medium;width: 250px !important;text-align: end;" >INFORME DE RESUMEN TÉCNICO</td>
									<td  style="text-align: end;">CODIGO :</td>
									<td  >AF-IF-106</td>
								</tr>
								<tr> 
									<td style="text-align: end;">Versión :</td>
									<td>03</td>
								</tr>
								<tr> 
									<td style="text-align: end;">Fecha de creación :</td>
									<td>01/07/2020</td>
								</tr>
								<tr> 
									<td style="text-align: end;">Última Revisión :</td>
									<td>19/10/2023</td>
								</tr>
								<tr> 
									<td style="text-align: end;">Pagina :</td>
									<td>1 de 1</td>
								</tr>
								<tr> 
									<td style="text-align: end;">Anexo :</td>
									<td>00</td>
								</tr>
							</table>
							<v-divider :thickness="4" class="border-opacity-100"></v-divider>
						</v-col>
						<v-col cols="6" align="center">
							<h5 class="under">DATOS DEL FUNDO</h5>
                            <table style="padding-left: 2px;">
								<tr >
									<td>PRODUCTOR:</td>
									<td colspan="2">{{ this.ReportTechnical.FrcName }}</td>
                                    <td><b>ID:</b> {{ this.FltID }}</td>
								</tr>
								<tr>
									<td>FUNDO:</td>
									<td colspan="2">{{ this.ReportTechnical.FagName }}</td>
								</tr>
								<tr>
									<td>NOMBRE DE PARCELA:</td>
									<td colspan="2">{{ this.ReportTechnical.FagPlotReference  }}</td>
								</tr>
								<tr>
									<td>DIRECCIÓN:</td>
									<td colspan="2">{{ this.ReportTechnical.FagAddress }}</td>
								</tr>
								<tr>
									<td>TIPO CULTIVO:</td>
									<td>{{ this.ReportTechnical.TypeCropName }}</td>

									<td v-if="ReportTechnical.TypeCrop == 1">COD. ORGÁNICO</td>
									<td v-if="ReportTechnical.TypeCrop == 1">{{ this.ReportTechnical.FltCodeOrganic }}</td>
								</tr>
								<tr>
									<td>COD. SENASA:</td>
									<td>{{ this.ReportTechnical.FltCodSenasa }}</td>
									<td>CULTIVO/VARIEDAD:</td>
									<td>{{ this.ReportTechnical.TypeCultiveName + ' / ' + this.ReportTechnical.VrtName }}</td>
								</tr>
								<!-- <tr>
									<td>CULTIVO/VARIEDAD</td>
									<td>{{ this.ReportTechnical.TypeCultiveName + ' / ' + this.ReportTechnical.VrtName }}</td>
								</tr> -->
								<tr>
									<td>RESPONSABLE:</td>
									<td colspan="2">{{ this.ReportTechnical.TvSupervisorName }}</td>
								</tr>
								<tr>
									<td>LOCALIDAD:</td>
									<td>{{ this.ReportTechnical.ZonGroupName }}</td>
									<td>AÑO SIEMBRA:</td>
									<td>{{ this.ReportTechnical.FltSowingYear }}</td>
								</tr>
								<!-- <tr>
									<td>AÑO SIEMBRA</td>
									<td>{{ this.ReportTechnical.FltSowingYear }}</td>
								</tr> -->
								<tr>
									<td>N° CILINDROS:</td>
									<td>{{ this.ReportTechnical.FltCylinder }}</td>
									<td>ZONA:</td>
									<td>{{ this.ReportTechnical.ZonDescription }}</td>
								</tr>
								<tr>
									
								</tr>
								<!-- <tr>
									<td>ZONA</td>
									<td>{{ this.ReportTechnical.ZonDescription }}</td>
								</tr> -->
								<tr>
									<td>N° PLANTA:</td>
									<td>{{ this.ReportTechnical.FltPlants }}</td>
									<td>AREA:</td>
									<td>{{ this.ReportTechnical.FltHectares }}</td>
								</tr>
								<tr>
									<td>CAUDAL:</td>
									<td>{{ this.ReportTechnical.FltFlow }}</td>
									<td>CAMPAÑA:</td>
									<td>{{ this.ReportTechnical.CmpName }}</td>
								</tr>
								<tr>
									<td>LATITUD:</td>
									<td>{{ this.ReportTechnical.FltLatituded }}</td>
									<td>LONGITUD:</td>
									<td>{{ this.ReportTechnical.FltLength }}</td>
								</tr>
							</table>
						</v-col>
						<v-col cols="6"  >
							<h5 class="under">REGISTRO DE APLICACIONES FITOSANITARIAS Y FERTILIZANTES</h5>						
							<table style="line-height: 0.8em;">
								<tr>
									<td class="tdcorto">Fecha de visita</td>
									<td class="tdancho">Descripcion</td>
									<td class="tdcorto">Fecha que aplico</td>
								</tr>
								<tr v-for="(item, index) in PhytosanitaryEvaluation" :key="index">
									<td class="tdcorto">{{ $fun.isValidDateTime(item.VstDate) ? moment(item.VstDate).format("DD/MM/YYYY") : '' }}</td>
									<!-- <td class="tdcorto">{{ item.PapDate != '' ? moment(item.PapDate).format("DD/MM/YYYY") : '' }}</td> -->
									<td class="tdcortoc">{{ item.TasProductsApplicationName }}</td>
									<td class="tdcorto">{{  $fun.isValidDateTime(item.PapDateEnd) ? moment(item.PapDateEnd).format("DD/MM/YYYY") : '' }}</td>
								</tr>
							</table>
						</v-col>
					</v-row>
				
					<!-- <v-row>
						<v-col cols="6"> 
							<template>
								<v-card>
									<v-card-title  class="justify-center" style="font-size: xx-small;">
										{{ "EVOLUCIÓN DE ETAPA FENOLÓGICA" }}
									</v-card-title>
									<v-card-text>
										<template>
											<LineChartGenerator
												:chart-options="chartOptions"
												:chart-data="chartData"
												:chart-id="chartId"
												:dataset-id-key="datasetIdKey"
												:plugins="plugins"
												:css-classes="cssClasses"
												:styles="styles"
												:width="width"
												:height="height"
											/>
										</template>
									</v-card-text>
								</v-card>
								
							</template>
						</v-col>	
						<v-col  cols="5">
							<template>
								<technical-report-graph-evaluation
									:filter="filter"
									:hasSearch="hasSearch"
									@EndSearch="EndSearch($event)"
								></technical-report-graph-evaluation>
							</template>
						</v-col>
					</v-row>
					 -->
					<v-row style="margin-bottom: 50px;">
						<v-col cols="8" align="center">
							<h5 class="under">REPORTE TECNICO - APLICACIONES RECOMENDADAS</h5>
                            <table border="1" style="line-height: 0.8em;">
								<thead>
									<tr>
										<td class="tdcorto">Fecha de visita</td>
										<td class="tdcorto">N° Mezcla</td>
										<td class="tdcorto">Tipo de Aplicacion</td>
										<td class="tdcorto">N° Prod.</td>
										<td class="tdcorto">Producto</td>
										<td class="tdcorto">Dosis</td>
										<td class="tdcorto">UM</td>
										<td class="tdcorto">Plaga - Enfermedad - Maleza</td>
										<td class="tdancho">Procedimiento</td>
									</tr>
								</thead> 
								<template v-for="(itemdate, dindex)  in VisitRecomendationNew">
									<template v-for="(itemtype, hindex) in itemdate.TypeApplication">
										<tr v-for="(product, index) in itemtype.productos" :key="product.ID">
											<td  v-if="hindex == 0 && index == 0" :rowspan="itemdate.cantProduct">{{ moment(itemdate.VstDate).format("DD/MM/YYYY") }}</td>
											<td style="text-align: center;" v-if="index == 0" :rowspan="itemtype.productos.length">{{ itemtype.Line }}</td>
											<td v-if="index == 0" :rowspan="itemtype.productos.length">{{ itemtype.type }}</td>
											
											<td style="text-align: center;">{{ product.Line }}</td>
											<td>{{ product.LsDescription }}</td>
											<td style="text-align: center;">{{ product.RcmDose }}</td>
											<td>{{ product.TypeUnitName }}</td>
											<td>{{ itemtype.PlName }}</td>
											<td v-if="index == 0" :rowspan="itemtype.productos.length">{{ itemtype.RchNote }}</td>
										</tr>
									</template>
								</template>
							</table>
						</v-col>
						<v-col cols="4" align="center">
                            <v-row justify="center">
                                <h5 class="under">ACTIVIDADES</h5>
                            </v-row>							
                            <v-row>
                                
								<v-col>
									<v-row justify="center">
										<h5 class="under">RECOMENDADAS</h5>
									</v-row>
                                    <v-row justify="center">
										<table width="100%" style="line-height: 0.8em;">
											<tr>
												<td class="tdcorto">Actividad</td>
												<td class="tdcorto">Fecha de Visita</td>
											</tr>
											<tr v-for="(item, index) in VisitActivityCultural" :key="index">
												<td class="tdcorto tdheight">{{ item.TypeActivityName }}</td>
												<td class="tdcorto tdheight">{{ moment(item.VstDate).format("DD/MM/YYYY") }}</td>
											</tr>
										</table>
									</v-row>
								</v-col>
								<v-col>
									<v-row justify="center">
										<h5 class="under">REALIZADAS</h5>	
                                    </v-row>
                                    <v-row >
										<table width="80%" style="line-height: 0.8em;">
											<tr>
												<td class="tdcorto">Actividad</td>
												<td class="tdcorto">Fecha Inicio</td>
												<td class="tdcorto">Fecha Fin</td>
											</tr >
											<tr v-for="(item, index) in PerformedActivities" :key="index">										
												<td class="tdcorto tdheight">{{ item.TypeActivityName }}</td>
												<td class="tdcorto tdheight">{{ moment(item.PacBeginDate).format("DD/MM/YYYY") }}</td>
												<td class="tdcorto tdheight">{{ moment(item.PacEndDate).format("DD/MM/YYYY")  }}</td>
											</tr>
										</table>
									</v-row>                                    
								</v-col>                                
							</v-row>
                            <v-row >
                                <v-col>
                                    <h5 class="under">EVALUACION DE INSTALACIONES</h5>                                   						
                                    <table  width="100%" style="line-height: 0.8em;">
                                        <tr>
                                            <td class="tdcorto">Fecha de Visita</td>
                                            <td class="tdcorto">Estado</td>
                                            <td class="tdcorto">Instalacion</td>
                                        </tr>
                                        <tr v-for="(item, index) in InstallationEvaluation" :key="index">
                                            <td class="tdcorto">{{ item.DateVisit }}</td>
                                            <td class="tdcorto">{{ item.StrStatus }}</td>
                                            <td class="tdcorto">{{ item.TypeInstallationName }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
						</v-col>
					</v-row>	
					<!-- <row>
						<v-col style="text-align: center;" cols="6">
							<v-img v-if="SignatureImage != null" contain :src="SignatureImage" height="200"></v-img>
						</v-col>
						<v-col style="text-align: center;"  cols="6">
							<v-img v-if="SignatureImageSuper != null" contain :src="SignatureImageSuper" height="200"></v-img>

						</v-col>
					</row> -->
					<v-row >
						<v-col style="text-align: center;">_____________________________</v-col>
						<v-col style="text-align: center;">_____________________________</v-col>
					</v-row>	
					<v-row >
						<v-col style="margin: -20px;text-align: center;">SUPERVISOR</v-col>
						<v-col style="margin: -20px;text-align: center;">GERENTE DE OPERACIONES AGRICOLAS</v-col>
					</v-row>
					<v-row >
						<v-col style="margin: 0px;text-align: center;">{{ this.ReportTechnical.TvSupervisorName }}</v-col>
						<v-col style="margin: 0px;text-align: center;">{{ this.BossName }}</v-col>
					</v-row>			
				</v-card-text>
	        </section>> 
			
		 </vue-html2pdf>
	</v-container>
    </div>		
		
	
</template>


<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
	import TechnicalReportGraphEvaluation from './TechnicalReportGraphEvaluation.vue';

	import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";
	import _sHelper from "@/services/HelperService";

	import VueHtml2pdf from 'vue-html2pdf'

	import { mapFields } from "vuex-map-fields"

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	} from 'chart.js'


	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	)

	export default {
		name: 'LineChart',
		components: {
			LineChartGenerator,
			SSelectLot,
			TechnicalReportGraphEvaluation,
			VueHtml2pdf
		},

		props: {
			chartId: {
				type: String,
				default: 'line-chart'
			},
			datasetIdKey: {
				type: String,
				default: 'label'
			},
			width: {
				type: Number,
				default: 400
			},
			height: {
				type: Number,
				default: 400
			},
			cssClasses: {
				default: '',
				type: String
			},
			styles: {
				type: Object,
				default: () => {}
			},
			plugins: {
				type: Array,
				default: () => []
			},
            filter:null,
            hasSearch: false,
            downloadpdf: false,
		},
		
		data() {
			return {				
				year: null,
				FltObject: null,
				
				itemsGraph : [],
				labelGraph : [],
				chartData: {
					labels: [],
					datasets: [
						{
							label: 'Data One',
							backgroundColor: '#f87979',
							data: [40, 39, 10, 40, 39, 80, 40]
						},
						{
							label: 'Data Two',
							backgroundColor: '#1446F0',
							data: [23, 45, 5, 13, 45, 88, 10]
						}
					]
				},
				chartOptions: {
					responsive: true,
					maintainAspectRatio: false
				},
				ReportTechnical: {},
				PerformedActivities: [],
				VisitRecomendation: [],
				VisitActivityCultural: [],
				PhytosanitaryEvaluation: [],
				InstallationEvaluation: [],

				progress: 0,
				VisitRecomendationNew: [],
                FltID: 0, 
				SignatureImage: null,
				SignatureImageSuper: null,
				BossName: "SANCHEZ CUEVA CARLOS DANIEL",
			}
		},
        watch: {
			hasSearch(newValue, oldValue) {
				if(this.hasSearch)
				{
					this.getDataInitial();

				}
			},
            downloadpdf(newValue, oldValue) {
                if(this.downloadpdf)
				{
					this.downloadPdf();
				}
            }
		},
		cumpoted:{

			...mapFields(["controlValue"]),

			htmlToPdfOptions() {
				return {
					margin: 0,

					filename: "hee hee.pdf",

					image: {
					type: "jpeg",
					quality: 0.98,
					},

					enableLinks: true,

					html2canvas: {
					scale: this.controlValue.pdfQuality,
					useCORS: true,
					},

					jsPDF: {
						unit: "in",
						format: this.controlValue.pdfFormat,
						orientation: this.controlValue.pdfOrientation,
						},
					};
				},
			},
		
		methods: {
			getImage(document, asigSig) {
				 
      			_sHelper.getPhotoFignature(document, this.$fun.getUserID()).then((rsp) => {
					const blob = new Blob([rsp.data], {
					type: "image/jpg",
					});

					let image = blob;
					var reader = new FileReader();
					reader.readAsDataURL(image);
					reader.onloadend = () => {
					asigSig(reader.result);
					};
				});
			},
			downloadPdf() {
			
				this.$refs.html2Pdf.generatePdf();
			},
			onProgress(progress) {
				this.progress = progress;
				console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
					 html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
							const totalPages = pdf.internal.getNumberOfPages()
							for (let i = 1; i <= totalPages; i++) {
								pdf.setPage(i)
								pdf.setFontSize(10)
								pdf.setTextColor(150)
								pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
							} 
						}).save()
				},

			EndSearch()
			{
				this.$emit("EndSearch");
			},

			getDataInitial() {
				// this.hasSearch = true;
				this.itemsGraph = [];
				this.labelGraph = [];
                this.FltID = this.filter.FltID;
				// if(this.FltObject != null && this.year > 0)
				// {
				// 	this.filter.FltID = this.FltObject.FltID;
				// 	let BeginDateOld = this.year + '/01/01';
				// 	this.filter.VstDate = this.$fun.formatDateView(BeginDateOld);
					_sVisit
					.graphvisit(this.filter, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							let data = resp.data;
							
							if(data.length > 0)
							{
								//para obtener los encabezados o label
								let arrayLabel = [];
								
								arrayLabel.push(...Object.keys(data[0])); 
								
								arrayLabel.forEach(element => {
									if(element != "FENOLOGIA" && element != "FENOLOGIACOLOR")
									{
										this.labelGraph.push(element);
									}
								});


								this.chartData.labels = this.labelGraph;

								
								//para la data
								resp.data.forEach(element => {
									let objData = {};
									objData.label = element.FENOLOGIA;
									objData.backgroundColor = element.FENOLOGIACOLOR;
									objData.borderWidth= 3;
									objData.borderColor= element.FENOLOGIACOLOR;;
									let data = Object.values(element);
									let newData = [];
									data.forEach(ind => {
										//console.log('agregar ', ind > 0 );
										if(ind > 0 || ind == null)
										{
											if(ind == null)
											{
												ind = 0;
											}
											newData.push(ind);
										}
									})
									objData.data = newData;
									this.itemsGraph.push(objData);
									this.chartData.datasets = this.itemsGraph;
									
								})
								
								
							}
						}
					});
				// }

				_sVisit.reportAll(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.VisitRecomendationNew = [];
						this.VisitRecomendation = [];
						
						this.ReportTechnical = resp.data;
						this.PerformedActivities = this.ReportTechnical.PerformedActivities;
						this.VisitRecomendation = this.ReportTechnical.VisitRecomendation;
						this.VisitActivityCultural = this.ReportTechnical.VisitActivityCultural;
						this.PhytosanitaryEvaluation = this.ReportTechnical.PhytosanitaryEvaluation;
						this.InstallationEvaluation = this.ReportTechnical.InstallationEvaluation;
						
						
						console.log("->", this.VisitRecomendation);
						this.VisitRecomendation.forEach((element, i) => {
							
								let objData = {}
								if(i == 0 || (this.VisitRecomendation[i-1].VstDate != this.VisitRecomendation[i].VstDate))
								{
									objData.VstDate = element.VstDate;
								}
								if(i == 0 || (this.VisitRecomendation[i-1].VstID != this.VisitRecomendation[i].VstID))
								{
									let typeaplicationS = [];

									let cantproduct = 0;
									let idtype = 1;
									this.VisitRecomendation.forEach((elm, k) => {
										if(element.VstDate == elm.VstDate)
										{
											let typeaplication = {};
											let productos = []
											
											typeaplication.Line = idtype;
											typeaplication.type = elm.TypeApplicationName;
											typeaplication.RchID = elm.RchID;
											typeaplication.PlName = elm.PlName;
											typeaplication.RchNote = elm.RchNote;
											
											// let idproduct = 1;
											// this.VisitRecomendation.forEach((obj, j) => {
											// 	if(elm.RchID == obj.RchID && elm.TypeApplication == obj.TypeApplication)
											// 	{
											// 		let producto = {};
                                            //       producto.ID = i;
											// 		producto.Line = idproduct;
											// 		producto.LsDescription = obj.LsDescription;
											// 		producto.RcmDose = obj.RcmDose;
											// 		producto.TypeUnitName = obj.TypeUnitName;													
											// 		productos.push(producto);
											// 		idproduct++;
											// 		cantproduct++;
											// 	}									
											// })		

											//cambios
											let idproduct = idtype;
											let producto = {};
											producto.ID = idtype;
											producto.Line = idproduct;
											producto.LsDescription = elm.LsDescription;
											producto.RcmDose = elm.RcmDose;
											producto.TypeUnitName = elm.TypeUnitName;													
											productos.push(producto);
											idproduct++;
											cantproduct++;

											//cambios
											
											typeaplication.productos = productos;
											typeaplicationS.push(typeaplication);
											idtype++;
										}										
									})
									
									objData.cantProduct = cantproduct;
									objData.TypeApplication = typeaplicationS;
									// console.log(objData);
									this.VisitRecomendationNew.push(objData);
									
								}
									
						});
						console.log("VisitRecomendationNew->", this.VisitRecomendationNew);

						//FOTOS DE LAS FIRMAS
						// this.getImage(this.ReportTechnical.TvSupervisorName, (blob) => {
						// 	this.SignatureImage = blob;
						// });
						// this.getImage(this.BossName, (blob)=> {
						// 	this.SignatureImageSuper = blob;
						// } );
					}
				})
			},

			downloadGraph()
			{
				
			}
		},

		created () {
			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
            

		},
		computed:{
			
		},
	}

	
</script>


<style>

table{
	border-radius:5px;
	border: 1px solid #0d0e0f; 
	border-collapse: separate;
	border-spacing: 0px;
	background-color:#FFFFFF;
	
}
td,th {
    /* border-radius:5px; */
    /* border: 2px solid #777777; */
    padding: 0px; 
   	width: 125px;
    height: 15px; 
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
    display: table-cell;
	font-size: 7px;
}
td.tdheight{
	height: 20;
}
.tdancho{
	/* border-radius:5px;  */
	text-align: center;
	border: 1px solid #777777;
	/* font-size: x-small; sa*/
	width: 300px;
	font-size: 7px;
}
.tdcorto{
	/* border-radius:5px;  */
	text-align: center;
	/* font-size: x-small; sa*/
	border: 1px solid #777777;
	font-size: 7px;
	width: 60px;
}
.tdcortoc{
	/* border-radius:5px;  */
	/* text-align: center; */
	/* font-size: x-small;sa */
	border: 1px solid #777777;
	font-size: 7px;
	width: 60px;
	padding-left: 1px;
}
.tdcortoca{ 
	border: 1px solid #777777;
	font-size: 8px;
	width: 60px;
	padding-left: 1px;
}
h5.under {
  text-decoration: underline;  
}


</style>
