<template>
	<v-card>
		<v-container>
			<v-row>
				<v-col cols="12" lg="6" md="6" class="pt-0">
					<h1>Reportes</h1>			
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="6" md="6" class="pt-0">
					<v-list>
						<v-list-item>
							<v-list-item-avatar>
							<v-btn
								fab @click="exportExcel(1)"
								><v-icon>mdi-cloud-download</v-icon>
							</v-btn>
							</v-list-item-avatar>
							<v-list-item-content>
							<v-list-item-title>
								<b><a @click="exportExcel(1)"
									style="color: black">
									PROGRAMACION DE COSECHAS DIARIAS - A.T</a>
								</b>
							</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-action>
								<!-- <v-btn
									fab
									small
									@click="OpenDialogTechnicalReport()"
								>
									<v-icon 
										color="red darken-2"
									>
										far fa-file-pdf
									</v-icon>
								</v-btn> -->
								<v-btn
									fab
									small
									@click="OpenDialogTechnicalReportGraph()"
								>
									<i class="far fa-chart-bar"></i>
								</v-btn>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									Informe de resumen técnico
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog
		v-model="dialogDownload"
		v-if="dialogDownload"
		width="600"
		>
		<v-card>
			<v-container>
				<v-row>
					<s-toolbar
						label="Descargar Reporte"
						excel
						@excel="ReportDownloadExcel"
						pdf
						@pdf="ReportDownloadPDF"
					>

					</s-toolbar>
				</v-row>
				<v-row>
					<v-col cols="6">
						<s-date 
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
								></s-date>
										</v-col>
							<v-col cols="6">
											<s-date 
								label="Fecha Fin"
								v-model="filter.cDateFin"
								></s-date>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<s-select-definition
							v-model="filter.HvsStatus"
							label="Estado de Programacion de Cosecha"
							:def="1236" >
						</s-select-definition>
					</v-col>					 
				</v-row>
			</v-container>
		</v-card>
		</v-dialog>

		<!-- dialog para reporte de informe tecnico -->
		<v-dialog
			v-if="dialogReportTechnicalReport"
			v-model="dialogReportTechnicalReport"
			 
			persistent
			fullscreen
		>
			<v-card dense outlined class="mx-auto">
				<v-card-title>
					Reporte de Brix
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<s-select 
								label="Año" 
								:items="years" 
								outlined 
								hide-details 
								dense 
								v-model="year"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-select-lot
								label="Sub Lote"
								clearable
								return-object
								v-model="FltObject"
								@input="InputTasFarmLot($event)"
								full
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="success"
						text
						@click="DownloadReportTechnicalReport()"
					>
						Descargar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="orange"
						text
						@click="dialogReportTechnicalReport = false"
					>
						Cancelar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog para graficos -->
		<!-- <v-dialog 
			v-model="dialogTechnicalReportGraph"
			v-if="dialogTechnicalReportGraph"
			width="800"
		> 
			<technical-report-graph></technical-report-graph>
		</v-dialog> -->
		<v-dialog
			 
			v-model="dialogTechnicalReportGraph"
			v-if="dialogTechnicalReportGraph"
			width="unset"
		>
			<s-toolbar
				close
				@close="dialogTechnicalReportGraph = false;"
			/>
			<technical-report-graph></technical-report-graph>
		</v-dialog>
	</v-card>


</template>

<script>
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
	import TechnicalReportGraph from './TechnicalReportGraph.vue';

	import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
	import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";
	

	export default {
		components: {
			SSelectLot,
			TechnicalReportGraph,
		},

		data() {
			return {
				querys: [],
				dialogDownload: false,
				filter: {},
				xFlag : false,
				report: {},
				processing: false,
				dialogReportTechnicalReport: false,
				dialogTechnicalReportGraph : false,
				year: null, 
				FltObject: null
			}
		},
		methods: {
			OpenDialogTechnicalReportGraph()
			{
				this.dialogTechnicalReportGraph = true;
			},

			OpenDialogTechnicalReport()
			{
				this.filter = {};
				this.dialogReportTechnicalReport = !this.dialogReportTechnicalReport
			},

			DownloadReportTechnicalReport()
			{
				this.filter.FltID = this.FltObject.FltID;
				let BeginDateOld = this.year + '/01/01';
				this.filter.VstDate = this.$fun.formatDateView(BeginDateOld);
				_sVisit
				.tecnicalreportpdf(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.downloadFile(resp.data, this.$const.TypeFile.PDF, "d");
					}
					/* this.$fun.downloadFile(
						resp.data,
						this.$const.TypeFile.PDF,
						"Reporte Técnico"
					); */
				})
				console.log('descargar el pdffffff', this.filter);
			},
			
			InputTasFarmLot(item)
			{
				console.log('click en lote ', item);
			},

			exportExcel(xFlag) {
			
				this.dialogDownload = true;
				this.xFlag = xFlag;
				
			},

            ReportDownloadExcel() {

                if (this.xFlag == 1) {
                    
                    let parametervalue = [];

                    parametervalue.push(this.filter.cDateInitial)
                    parametervalue.push(this.filter.cDateFin)
                    parametervalue.push(this.filter.HvsStatus)
                    let parametergen = " @dateBegin,@dateEnd ,@HvsStatus";
                    this.downloadReport("PROGRAMACION DE COSECHAS DIARIAS - A.T","TasProgramingCosechaAll",parametervalue,parametergen);


                } 
            },
			ReportDownloadPDF(){
				if(this.xFlag == 2){
					
					let obj = {};
					obj.Flag = this.xFlag;"",""
					obj.NameReport = "AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO)"; 
					obj.NameProc = "";
					obj.DateBegin = this.filter.cDateInitial;
					obj.DateEnd = this.filter.cDateFin;


					console.log("x filter", obj);
					
					_sLotProdCalibratedService
						.reportCalibratePDF(obj, this.$fun.getUserID())
						.then((r) => {

							this.$fun.downloadFile(
								r.data,
								this.$const.TypeFile.PDF,
								obj.NameReport
								);
						
						});
				}
			},
			downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			}, 
			getOption()
			{
				let options = JSON.parse(localStorage.getItem("UserMenu"));
				let NameMenuMain = localStorage.getItem("NameMenuMain");
				var optionSelected;
				options.forEach(element => {
					console.log(element.OptCode, NameMenuMain);
					/* if (element.OptCode == NameMenuMain)
					{
						console.log('entro');
						optionSelected = element.OptCode
					} */
				});
				console.log('NameMenuMain ', optionSelected);
			},

			getQuerys(item) {
				_sQryConfigurationService
					.list(item, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
							this.querys = resp.data;
						}
					});
			},
		},

		created () {
			this.getOption();

			this.years = this.$fun.getTop10LastYears();
			this.year = this.$moment().year();
		},
	}
</script>

<style scoped>

</style>